<template>
  <div class="temp-page-container home">
    <OnBoardingLoader
      :title="'Redirecting...'"
      :description="'You are being redirected to the login screen.'"
    />
  </div>
</template>

<script>
import Config from '@/config';
import OnBoardingLoader from '@/components/OnBoardingLoader.vue';

export default {
  name: 'Login',
  created() {
    const params = {};
    params['rz-app-key'] = Config.rzAppKey;
    params.callback_url = window.location.href.replace('login', 'login-response');
    if (this.$route.query && this.$route.query.next_url) {
      params.next_url = this.$route.query.next_url;
    }
    console.log('loginpage');
    window.location.href = this.buildURL(Config.rzLoginPath, params);
  },
  methods: {
    buildURL(base, params) {
      const q = Object.keys(params).map((key) => [key, params[key]].map(encodeURIComponent).join('=')).join('&');
      return `${base}?${q}`;
    },
  },
  components: {
    OnBoardingLoader,
  },
};
</script>
