<template>
  <div class="loading-wrapper" v-if="title || description">
    <h2>{{title}}</h2>
    <p>{{description}}</p>
    <div class="spinner">Loading...</div>
  </div>
</template>
<style lang="scss" scoped>
.loading-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--global--primary--BackgroundColor);
  text-align: center;
  padding: size(200) size(15);
  z-index: 11;
  h2 {
    font-size: size(42);
    font-weight: bold;
    line-height: size(48);
    padding-right: 0;
    margin-bottom: size(12);
    color: var(--global--main--title--Color);
  }
  p {
    @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
    max-width: initial;
  }
  .spinner {
    margin: size(10) auto size(60);
    position: relative;
    border-top: size(4) solid var(--global--common-link--Color);
    border-right: size(4) solid var(--global--common-link--Color);
    border-bottom: size(4) solid var(--global--common-link--Color);
    border-left: size(4) solid transparent;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
    font-size: 0;
    &, &:after {
      border-radius: 50%;
      width: size(80);
      height: size(80);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
<script>
export default {
  name: 'OnBoardingLoader',
  props: {
    title: {
      type: String,
      // default: 'Please wait',
    },
    description: {
      type: String,
      // default: 'We are Validating now...',
    },
  },
};
</script>
